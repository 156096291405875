<template>
  <div>
    <v-card>
      <v-card-title>入札実績(決定)</v-card-title>
      <template>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
      </template>
      <v-spacer></v-spacer>
      <v-form class="ma-6 mt-0">
        <v-row align="center">
          <v-col cols="12" md="2">
            <label color="primary" for="bidID">入札商品</label>
          </v-col>
          <v-col cols="12" md="4">
            <!-- <v-text-field
              v-model="Form.auc_id"
              outlined
              dense
              readonly
              hide-details
            ></v-text-field> -->
            <a href="javascript:void(0);" @click="openOverviewDialog">{{ Form.auc_code }}({{ Form.auc_id }})</a>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="2">
            <label for="bidName">入札商品名</label>
          </v-col>
          <v-col cols="12" md="8">
            {{ Form.auc_name_ja }}
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" md="2">
            <label for="bidName">入札期間</label>
          </v-col>
          <v-col cols="12" md="8">
            {{ Form.auc_ssd }} ～{{ Form.auc_sed }}
          </v-col>
        </v-row>
        <!-- <v-row align="center">
          <v-col cols="12" md="2">
            <label for="Form.auc_ovv_ja">概要</label>
          </v-col>
          <v-col cols="12" md="8">
            <v-textarea
              v-model="Form.auc_ovv_ja"
              outlined
              dense
              hide-details
              readonly
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="3">
          </v-col>
        </v-row> -->
        <v-spacer></v-spacer>
        <v-data-table
          v-model="selectedBiddings"
          :headers="headers"
          show-select
          item-key="bid_id"
          :items="Form.details"
          hide-default-footer
          class="elevation-1 mt-5"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>入札情報</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                :disabled="OperationDisabled"
                @click="openOperationDialog"
              >
                <v-icon
                  size="17"
                  class="me-1"
                >
                  {{ icons.mdiFileCheckOutline }}
                </v-icon>
                <span>操作</span>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:[`item.bid_amount`]="{ item }">
            {{ formatAmount(item.ccy_mark, item.bid_amount) }}
          </template>
          <template v-slot:[`item.bid_stat_desc`]="{ item }">
            <div class="d-flex justify-center">
              <v-chip
                style="width:120px"
                :color="getColor(item.bid_stat)"
                class="d-flex justify-center"
              >
                <span>{{ item.bid_stat_desc }}</span>
              </v-chip>
            </div>
          </template>
        </v-data-table>
      </v-form>
      <v-container pt-10>
        <v-row>
          <v-col offset-md="5" cols="12">
            <!-- <v-btn color="primary">
              Submit
            </v-btn> -->
            <v-btn type="reset" class="mx-2" outlined :to="{ name: 'bidding-list' }">
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <bidding-win
      :dialog="dialog"
      :auc-id="Form.auc_id"
      :auc-name="Form.auc_name_ja"
      :selected-biddings="selectedBiddings"
      @closeForm="closeOperationDialog"
    ></bidding-win>
    <BiddingOvv
      :id="Form.auc_id"
      :ovv-dialog="ovvDialog"
      @closeForm="closeOverviewDialog"
    ></BiddingOvv>
  </div>
</template>

<script>
import {
  mdiFileCheckOutline,
} from '@mdi/js'
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import BiddingWin from './BiddingWin.vue'
import BiddingOvv from './BiddingOverview.vue'

export default {
  components: {
    BiddingWin,
    BiddingOvv,
  },
  data: () => ({
    dialog: false,
    ovvDialog: false,
    submitStatus: false,
    selectedBiddings: [],
    OperationDisabled: true,
    icons: {
      mdiFileCheckOutline,
    },
    status: {
      1: '入札済', // waiting
      2: '失注', // wating
      9: '落札', // failed
    },

    Form: {
      no: '',
      auc_id: '',
      auc_ssd: '',
      auc_sed: '',
      auc_name_ja: '',
      auc_ovv_ja: '',
      details: [],
    },

    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    headers: [
      {
        text: 'ID.',
        value: 'bid_id',
        align: 'left',
        width: '10%',
      },
      {
        text: '入札日時',
        value: 'bid_dt',
        align: 'left',
        width: '15%',
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '25%',
      },
      {
        text: '入札金額',
        value: 'bid_amount',
        align: 'right',
        width: '35%',
      },
      {
        text: '状態',
        value: 'bid_stat_desc',
        align: 'center',
        width: '15%',
      },
    ],
  }),

  computed: {
    ...mapState('bidStore', ['selectedBid']),

    formatAmount() {
      return function (ccyMark, amount) {
        if (ccyMark === undefined || ccyMark === null) return ''
        if (amount === undefined || amount === null) return ''

        return ccyMark + amount.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      }
    },
  },

  watch: {
    selectedBiddings(val) {
      console.log('val:', val, val.length)
      if (val.length === 0) {
        this.OperationDisabled = true

        return
      }

      let ret = false

      for (let i = 0; i < val.length; i += 1) {
        console.log('bid_stat:', i, val[i].bid_stat)
        if (val[i].bid_stat > 9) {
          ret = true
          break
        }
      }

      console.log('ret:', ret)

      this.OperationDisabled = ret
    },
  },

  created() {
    this.loadData()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('bidStore', ['loadBid']),
    openOperationDialog() {
      this.dialog = true
    },
    closeOperationDialog() {
      this.loadData()
      this.dialog = false
    },
    loadData() {
      console.log('load Data')
      this.setOverlayStatus(true)

      this.loadBid(this.$route.params.id).then(() => {
        console.log('bid:', this.selectedBid)

        this.Form = this.$deepCopy(this.selectedBid)
        for (let i = 0; i < this.Form.details.length; i += 1) {
          this.Form.details[i].no = i + 1
        }
      }).catch(error => {
        console.log('error:', error)
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    openOverviewDialog() {
      this.ovvDialog = true
    },
    closeOverviewDialog() {
      this.ovvDialog = false
    },

    editItem(item) {
      let x = 0
      console.log('details:', this.Form.details)
      this.Form.details.forEach((elem, index) => {
        if (elem.no === item.no) {
          x = index
        }
      })

      let otherstatus = 1
      if (this.Form.details[x].bid_stat === 1) {
        this.Form.details[x].bid_stat = 2
        otherstatus = 3
      } else {
        this.Form.details[x].bid_stat = 1
      }

      this.Form.details.forEach((elem, index) => {
        if (index !== x) {
          this.Form.details[index].bid_stat = otherstatus
        }
      })
    },

    getColor(status) {
      if (status === 5) {
        return 'secondary'
      }

      if (status === 8) {
        return 'error'
      }

      if (status < 9) {
        return 'info'
      }

      if (status < 11) {
        return 'success'
      }

      return 'primary'
    },
  },
}
</script>
