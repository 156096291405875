<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span
          class="text-h5"
        >落札決定 ({{ aucId }} {{ aucName }})</span>
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0" style="max-width:100%">
          <v-row align="center">
            <v-col
              cols="12"
            >
              <v-data-table
                ref="selReservedTable"
                v-model="selectedCustomers"
                :headers="headers"
                :items="selectedBiddings"
                item-key="bid_id"
                hide-default-footer
                sort-by
                show-select
                class="elevation-1"
              >
                <template v-slot:[`item.bid_amount`]="{ item }">
                  {{ formatAmount(item.ccy_mark, item.bid_amount) }}
                </template>
              </v-data-table>
            </v-col>
            <!-- <v-col
              cols="12"
              md="2"
            >
              <span class="pl-2" style="font-size:16px;">決定結果</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="operation"
                :items="operationList"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          class="mx-2"
          color="primary"
          @click="submit()"
        >
          OK
        </v-btn>
        <v-btn
          class="mx-2"
          outlined
          @click="closeForm()"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions,
} from 'vuex'

export default {
  props: ['dialog', 'aucId', 'aucName', 'selectedBiddings'],
  data: () => ({
    operation: 0,
    selectedCustomers: [],

    apierror: [],
    headers: [
      {
        text: 'ID.',
        value: 'bid_id',
        align: 'left',
        width: '10%',
      },
      {
        text: '入札日時',
        value: 'bid_dt',
        align: 'left',
        width: '20%',
      },
      {
        text: '落札先',
        value: 'cust_abbr',
        align: 'left',
        width: '25%',
      },
      {
        text: '入札金額',
        value: 'bid_amount',
        align: 'right',
        width: '35%',
      },

      // {
      //   text: '判定',
      //   value: 'status',
      //   sortable: false,
      //   align: 'center',
      //   width: '15%',
      // },
    ],
  }),
  computed: {
    formatAmount() {
      return function (ccyMark, amount) {
        if (ccyMark === undefined || ccyMark === null) return ''
        if (amount === undefined || amount === null) return ''

        return ccyMark + amount.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
      }
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        console.log('dialog', val, this.selectedBiddings, this.aucId, this.aucName)
        this.$nextTick(() => {
          this.$refs.selReservedTable.toggleSelectAll(true)
        })
      }
    },
  },
  methods: {
    ...mapActions('bidStore', ['bidtowin']),

    closeForm() {
      this.selectedCustomers = []
      this.$emit('closeForm')
    },

    // 落札決定情報をsubmitする
    submit() {
      console.log('customers:', this.selectedCustomers, this.selectedBiddings)
      console.log('action:', this.operation)

      let custIds = ''
      for (let i = 0; i < this.selectedCustomers.length; i += 1) {
        // console.log('selected:', this.seletedCustomers[i].cust_id)
        custIds += `${this.selectedCustomers[i].cust_id.toString()},`
      }

      if (custIds.length === 0) {
        // eslint-disable-next-line no-alert
        window.alert('落札先を選択してください。')

        return
      }

      const submitForm = {
        auc_id: this.aucId,
        cust_ids: custIds.slice(0, -1),
      }

      console.log('submitForm:', submitForm)

      this.bidtowin(submitForm)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          this.apierror.status = error.response.data.status
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },
  },
}
</script>
